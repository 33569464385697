header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    background-color: #FFF;
    z-index: 2;
}

#header--logo {
    width: 150px;
    margin: 18px 0 24px 45px;
}

#header--nav ul,
#subnav ul {
    display: flex;
}

.nav--item,
.subnav--item {
    margin-right: 3rem
}

.nav--item a {
    color: #003330;
    text-decoration: none;
    transition: all 80ms ease;
}

.nav--button a {
    background-color: #00B2AD;
    color: #FFF;
    border-radius: 30px;
    padding: 0.5rem 1.5rem;
}

#subnav {
    display: block;
    width: 100%;
    height: 60px;
    background-color: #F2F9F9;
    box-shadow: 0px 20px 20px -20px rgba(17, 119, 132, 0.378);
}

#subnav ul {
    flex-direction: row;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
}

.subnav--item a {
    text-decoration: none;
    color: #117684;
    transition: all 80ms ease;
}

.subnav--item a:hover {
    color: #003330
}

.chevron--right {
    width: 40px;
    height: 40px;
}



/* ANIMATIONS */

.nav--item a:hover {
    color: #00B2AD;
}

.nav--button a:hover {
    background-color: #117684;
    color: #FFF;
}



/* RESPONSIVE MENU */

.hamburger {
    color: #00B2AD;
    border: 0;
    height: 50px;
    width: 50px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
    transition: all 160ms ease;
    position: absolute;
    top: 40px;
    right: 3rem;
    transform: translateY(-50%);
    display: none;
}

.hamburger:hover {
    background-color: #00B2AD;
    color: #F2F9F9;
}

@media screen and (max-width: 980px) {
    .hamburger {
        display: block;
    }
    .menu {
        display: none;
    } 
    #subnav ul {
        justify-content: flex-start;
        margin-left: 45px;
    }
    #header--nav .menu {
        position: absolute;
        top: 80px;
        left: 0;
        flex-direction: column;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 20px 20px -20px rgba(17, 119, 132, 0.378);

      }
      .nav--item {
        text-align: center;
        margin: 0;
        color: black;
        width: 100%;
        padding: 1.6rem 0;
        background-color: #F2F9F9;
        transition: all 80ms ease;
      }
      .nav--item:hover,
      .nav--button a:hover {
        background-color: #00B2AD;
      }
      .nav--item:hover  a {
        color: #F2F9F9;
      }

      /* isNavExpanded is false */
      .nav--hidden {
        display: none;
      }
      /* isNavExpanded is true */
      .nav--displayed {
        display: block;
      }
}