/* PANELS */

.panel {
    padding: 2rem;
    background-color: #FFF;
    margin-top: 2rem;
    border-radius: 8px;
    box-shadow: 0px 0px 16px -8px rgba(0, 51, 48, 0.25);

}

.panel--header {
    display: flex;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 0;
    justify-content: space-between;
    border-bottom: 2px solid rgba(167, 167, 167, 0.202);
}

.panel--edit {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.panel--content {
    display: flex;
}

.panel--col {
    width: 33%;
}

.edit--icon {
    width: 26px;
    opacity: 0.8;
}

#main--panel {
    max-width: 66%;
}

/* PROFILE PIC */

.panel--left_content .panel--edit img {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    opacity: 1;
    
    object-fit: cover;
    transition: all 120ms ease;
}

.panel--left_content .panel--edit img:hover {
    opacity: .4;
    box-shadow: 1px 1px 10px 10px rgba(160, 160, 160, 0.26);
}

.panel--left_content .panel--edit:hover img {
    opacity: .4;
}


.panel--left_content .panel--edit:hover::before {
    content: '';
}

.panel--left_content .panel--edit:hover::after {
    content: 'Modifier votre photo de profil ✏️';
    font-family: 'Raleway';
    color: var(--black);
    text-align: center;
    max-width: 200px;
    font-size: 1rem;
    font-weight: 600;
    position: absolute;
    
}

/* MODAL / POPUP */

.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal .header {
    margin: 2rem 0;
    font-size: 1.2rem;
    font-family: 'Raleway', sans-serif;
    color: var(--black);
}

.modal button {
    max-width: 300px;
    width: 80vw;
    border: none;
    background-color: var(--main);
    color: #FFF;
    min-height: 40px;
    border-radius: 30px;
    padding: 0 1.5rem;
    cursor: pointer;
    transition: all 80ms ease;
}

.modal button:hover {
    background-color: var(--dark);
    
}

.popup-content {
    margin: auto;
    background: var(--white);
    max-width: 100vw;
    max-height: 100vh;
    overflow-y: scroll;
    width: 500px;
    padding: 5px;
    border-radius: 8px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.content button {
    margin-top: 2rem;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.7);
}

.actions button {
    margin: 2rem 0;
    background-color: tomato;
}

.actions button:hover {
    background-color: indianred;
}

/* FORMS */

.panel--form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.panel--form_item {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 1rem;
}

.panel--form_item label {
    margin-bottom: 5px;
    font-size: 1rem;
    font-family: 'Raleway', sans-serif;
}

.panel--form_item input {
    max-width: 300px; 
    width: 80vw;
    border: none;
    border-bottom: 1px solid rgba(0, 2, 1, 0.219);
    border-radius: 0px;
    padding: 5px 5px 5px 0; 
    background: transparent;
}

.panel--col {
    width: 30%;
}

.panel--col li {
    list-style: none;
    padding: 6px 0;
}
.panel--col li h5 {
    font-size: 14px;
    color: var(--dark);
    font-weight: 600;
    letter-spacing: 0.07;
}

.panel--col li span {
    display: inline-block;
    width: 90%;
    font-weight: 600;
    padding: 12px 0;
    border-bottom: 2px solid rgba(167, 167, 167, 0.202);
    word-break: break-all;

}
/* WRAPPER */

.wrapper--profile {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
}
/* RESPONSIVE */

@media (max-width: 768px){
    #main--panel {
        padding: 12px;
        max-width: 100%;
        margin: 1rem auto;
    }
    .panel--content {
        width: 90%;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
    }

    .panel--left_content {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .wrapper--profile {
        margin-top: 1rem;
        width: 100%;
    }

    .wrapper--profile .panel--col  {
        width: 50%;
    }

}