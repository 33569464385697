footer {
    background-color: #117684;
    padding: 45px 45px 20px 45px;
    color: #F2F9F9;
    font-weight: 600;
}

.footer--links {
    display: flex;
}

.footer--logo {
    width: 150px;
}

.footer--socials__row {
    display: flex;
    height: 18px;
    align-items: center;
    padding: 2rem 0 1rem 0;
}

.footer--socials__icons {
    height: 1rem;
    margin-left: 1rem;
    fill: #FFF;
}

.footer--contact {
    text-decoration: none;
    color: #FFF;
}

.footer--contact:hover {
    text-decoration: underline;
}

.footer--links__lists {
    padding: 10px 0 0 5rem;
}

.footer--link {
    padding-bottom: 1rem; 
}

.footer--link a {
    color: #F2F9F9;
    text-decoration: none;
    font-weight: 400;  
    font-size: 14px;
}

.footer--legals__list {
    display: flex;
    justify-content: flex-end;
}

.footer--legals__list li {
    margin-left: 1rem;
    margin-top: 1rem;
}

@media screen and (max-width: 980px) {
    .footer--links {
        flex-direction: column;
    }

    .footer--links__lists {
        padding-left: 0;
        margin-top: 1.5rem;
    }

    .footer--legals__list {
        justify-content: flex-start;
    }

    .footer--legals__list li:first-of-type {
        margin-left: 0;
    }
}