.tooltip {
    border-radius: 8px 0 8px 8px;
    margin-bottom: 1rem;
    background-color: var(--dark);
    position: absolute;
    margin-top: 1rem;
    left:calc(50% - 600px);
    display: none;
    transition: all 80ms ease-in-out;
}

.password--field {
    max-height: 60px;
}

.password--field:focus-within .tooltip{
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 1rem;
    color: var(--white);
}

@media screen and (max-width: 850px) {
    .tooltip {
        border-radius: 8px;
        position: relative;
        left: 0;
        display: none;
        transition: all 80ms ease-in-out;
    } 
}