.btn {
    display: block;
    background-color: var(--main);
    color: #FFF;
    min-height: 40px;
    border-radius: 30px;
    padding: 0 1.5rem;
    cursor: pointer;
    transition: all 80ms ease;
}

.btn:hover {
    background-color: var(--dark);
}