.profile--title {
    font-size: 1.6rem;
    padding: 4rem 0 0 2rem;
    font-weight: 800;
    color: var(--black);
    text-transform: uppercase;
}

.profile--title span {
    font-weight: 400;
    opacity: 0.8;
}

.profile--picture {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    object-fit: cover;
}

.panel--content {
    margin: 2rem 1rem;
}

/* FORM */

#upload--pp {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#upload--pp input::file-selector-button {
  border: none;
  background: var(--main);
  padding: 10px 20px;
  border-radius: 30px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

/* RESPONSIVE */

