#register {
    width: 750px;
    min-height: 100%;
    margin: 2rem auto;
    border-radius: 8px;
    box-shadow: 0px 0px 16px -8px rgba(0, 51, 48, 0.25);
    display: flex;
}

.register--half {
    width: 50%;
    min-height: 100%;
}

#people--candidate {
    position: relative;
    top: 110px;
    right: 45px;
    width: 490px;
}

#people--recruiter {
    position: relative;
    top: 183px;
    right: 1px;
    width: 419px;
  }

.form--half {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.half--input {
    width: 40%;
}
#register--cgu,
#confidentiality {
    font-size: 12px;
}

.register--chbx {
    margin: 0;
    width: 120%;
}

#confidentiality {
    display: inline-block;
    width: 100%;
    text-align: left;
    margin: 1rem 0;
}


@media screen and (max-width: 850px) {
    #register--form {
        width: 100%;
    }
    #register {
        width: 100%;
        box-shadow: none;
    }
}