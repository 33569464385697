/* ALSO CONTAINS MAIN REGISTER FORMS RULES */
/* SEE IDs */


#login {
    width: 750px;
    height: 500px;
    margin: 2rem auto;
    border-radius: 8px;
    box-shadow: 0px 0px 16px -8px rgba(0, 51, 48, 0.25);
    display: flex;
}

.login--half{
    width: 50%;
    min-height: 100%;
}

#login--illustration, 
#register--illustration {
    border-radius: 0 8px 8px 0;
    background-color: var(--main);
    z-index: -2;
}

#login--logo, 
#register--logo {
    width: 200px;
    display: block;
    margin: 2rem auto;
}

#login--people, #register--people {
    position: relative;
    top: 55px;
    right: -5px;
    width: 420px;
}

#login--form, #register--form {
    text-align: center;
}

#form--title {
    margin: 3rem 0 0.8rem 0;
    font-size: 36px;
    color: var(--main);
}

#form--subtitle {
    color: var(--black);
}

#login--form form, 
#register--form form {
    width: 260px;
    margin: 2rem auto 0 auto;
}

.form--input {
    margin-bottom: 2rem;
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(0, 2, 1, 0.219);
    border-radius: 0px;
    padding-bottom: 5px; 
    margin-top: 1rem;
}

input:focus {
    border-color: var(--main);
}

.login--chbx, .register--chbx {
    text-align: left;
    border: none;
}

#form--btn {
    width: 100%;
    height: 40px;
    background-color: var(--main);
    border: none;
    border-radius: 30px;
    color: #fff;
    cursor: pointer;
    transition: all 80ms ease;
}

#form--btn:hover {
    background-color: var(--dark);
}

.access--links {
    display: inline-block;
    margin: 0 auto;
    font-size: 12px;
    text-align: left;
    width: 260px;
}

.access--links p {
    margin-bottom: 8px;
}

.access--links a {
    padding: 0 3px;
}

@media screen and (max-width: 850px) {
    #login--illustration, 
    #register--illustration {
        display: none;
    }

    #login {
        width: 100%;
        box-shadow: none;
    }

    #login--form {
        width: 100%;
    }

    
}